<template>
  <div>
    <card :corpId="corpId"></card>
  </div>
</template>

<script>
import card from '@/views/frm/corp/card/index.vue'
export default {
  components: {
    card
  },
  data() {
    return {
      corpId: null
    }
  },
  created() {
    this.corpId = this.getCorpId()
  }
}
</script>

<style>
</style>